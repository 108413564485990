import { CommonError, Spinner } from "components/utils";
import { dateFns, queryString } from "utilities";
import { ListHeader } from "./components/ListHeader";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { FinanceReportBalance, ReportDetails } from "api/finances/models";
import { financesActions } from "api/finances/actions";
import { useFinancesBalanceColumns } from "./useFinancesBalanceColumns";
import styles from "./FinancesBalance.module.css";
import { BalancePieChart } from "./components/BalancePieChart";
import { BalanceLineChart } from "./components/BalanceLineChart";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useFinancesFilters } from "./useFinancesFilters";

export enum RowType {
  "INCOME" = "INCOME",
  "SUMMARY" = "SUMMARY",
  "EXPENSE" = "EXPENSE",
}

export interface NormalizedFinanceBalanceReport extends ReportDetails {
  id: RowType | string;
  label: string;
  subRows?: Omit<NormalizedFinanceBalanceReport, "label">[];
}

export const FinancesBalance = () => {
  const { months, businessEntityId, years } = useFinancesFilters();
  const search = queryString.stringify({ months, businessEntityId, years });
  const { data, error, isLoading } = financesActions.useReportBalance({
    search,
  });

  const columns = useFinancesBalanceColumns();

  if (error) {
    return (
      <div className="d-flex flex-column h-100">
        <ListHeader />
        <div className="d-flex align-items-center justify-content-center flex-1">
          <CommonError status={error._httpStatus_} />
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="d-flex flex-column h-100">
        <ListHeader />
        <div className="d-flex align-items-center justify-content-center flex-1">
          <Spinner color="blue" size="big" text="trwa wczytywanie bilansu" />
        </div>
      </div>
    );
  }

  assertIsDefined(data);

  return (
    <div className="h-100 d-flex flex-1 flex-column">
      <ListHeader />
      <div className="d-flex flex-1 w-100 flex-column overflow-hidden">
        <div className="my-2 ml-3 overflow-auto">
          <Typography fontSize="18" fontWeight="600" className="mb-2">
            I kwartał 2024
          </Typography>

          <div className={styles.innerWrapper}>
            <div className={styles.charts}>
              <BalanceLineChart data={data} />

              <BalancePieChart label="Przychody" data={data!.summary!.income.components} />

              <BalancePieChart label="Koszty" data={data!.summary!.expense.components} />
            </div>
            <div className={styles.tables}>
              <div className="mb-3">
                <Table<NormalizedFinanceBalanceReport>
                  expandible
                  rows={normalizedData(data?.summary!)}
                  isLoading={false}
                  columns={columns}
                  error={error}
                  overrides={() => ({
                    row: row => getRowBackground({ rowType: row.id as RowType }),
                  })}
                  uiSchema={{
                    cell: { height: "26" },
                    header: { backgroundColor: "neutralWhite76" },
                  }}
                />
              </div>

              {data?.details.map(monthlyFinanceBalanceReport => (
                <div className="mb-3">
                  <Typography fontSize="14" fontWeight="600" className="ml-3 my-2 text-capitalize">
                    {dateFns.format(new Date(monthlyFinanceBalanceReport.timestamp), "LLLL yyyy")}
                  </Typography>
                  <Table<NormalizedFinanceBalanceReport>
                    expandible
                    rows={normalizedData(monthlyFinanceBalanceReport)}
                    isLoading={false}
                    columns={columns}
                    error={error}
                    overrides={() => ({
                      row: row => getRowBackground({ rowType: row.id as RowType }),
                    })}
                    uiSchema={{
                      cell: { height: "26" },
                      header: { backgroundColor: "neutralWhite76" },
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getRowBackground = ({ rowType }: { rowType: RowType }) => {
  if (rowType === RowType.INCOME) {
    return { className: styles.incomeRow };
  }

  if (rowType === RowType.EXPENSE) {
    return { className: styles.expenseRow };
  }

  if (rowType === RowType.SUMMARY) {
    return { className: styles.summaryRow };
  }
  return {};
};

const normalizedData = (data: FinanceReportBalance["summary"]) => {
  if (!data) return [];

  const income: NormalizedFinanceBalanceReport = {
    id: RowType.INCOME,
    label: "Przychody",
    ...data.income.total,
    subRows: data.income.components.map((row, index) => ({
      ...row,
      id: `income-${index}`,
    })),
  };
  const expense: NormalizedFinanceBalanceReport = {
    id: RowType.EXPENSE,
    label: "Koszty",
    ...data.expense.total,
    subRows: data.expense.components.map((row, index) => ({ ...row, id: `expense-${index}` })),
  };
  const summary: NormalizedFinanceBalanceReport = {
    id: RowType.SUMMARY,
    label: "Bilans",
    ...data.summary,
  };
  return [income, expense, summary];
};
