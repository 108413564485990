import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiCompany } from "components/miloDesignSystem/atoms/icons/MdiCompany";
import { FinancesBalanceRouting } from "routes/crm/FinancesBalanceRouting";

export const financesBalance: ModuleLink = {
  url: "finances-balance",
  label: "Podmioty biznesowe",
  icon: MdiCompany,
  subSection: <></>,
  routing: FinancesBalanceRouting,
  isAccessible: true,
};
