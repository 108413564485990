// src/ENDPOINTS.ts
const local = "http://localhost:8000/";

const development = "https://development.api.imilo.pl/";

// demo
const demo = "https://demo.api.imilo.pl/";

// amsline
const amsline = "https://ams-line.api.imilo.pl/";
const amslineTest = "https://ams-line.test.api.imilo.pl/";

// bromarkt
const bromarkt = "https://bromarkt.api.imilo.pl/";
const bromarktTest = "https://bromarkt.test.api.imilo.pl/";

// drewmix
const drewmix = "https://drewmix.api.imilo.pl/";
const drewmixTest = "https://drewmix.test.api.imilo.pl/";

// gib
const gib = "https://gib.api.imilo.pl/";
const gibTest = "https://gib.test.api.imilo.pl/";

// graingold
const gg = "https://gg.api.imilo.pl/";
const ggTest = "https://gg.test.api.imilo.pl/";

// grekpol
const grekpol = "https://grekpol.api.imilo.pl/";
const grekpolTest = "https://grekpol.test.api.imilo.pl/";

// idm
const idm = "https://idm.api.imilo.pl/";
const idmTest = "https://idm.test.api.imilo.pl/";

// kef
const kef = "https://kef.api.imilo.pl/";
const kefTest = "https://kef.test.api.imilo.pl/";

// kenix
const kenix = "https://kenix.api.imilo.pl/";
const kenixTest = "https://kenix.test.api.imilo.pl/";

// lukmebel
const lukmebel = "https://lukmebel.api.imilo.pl/";
const lukmebelTest = "https://lukmebel.test.api.imilo.pl/";

// mars
const mars = "https://mars.api.imilo.pl/";
const marsTest = "https://mars.test.api.imilo.pl/";

// moderno
const moderno = "https://moderno.api.imilo.pl/";
const modernoTest = "https://moderno.test.api.imilo.pl/";

// mati-team
const mteam = "https://m-team.api.imilo.pl/";
const mteamTest = "https://m-team.test.api.imilo.pl/";

// marti meble
const martimeble = "https://mm.api.imilo.pl/";
const martimebleTest = "https://mm.test.api.imilo.pl/";

// mebline
const mebline = "https://mebline.api.imilo.pl/";
const meblineTest = "https://mebline.test.api.imilo.pl/";

// meblohand
const meblohand = "https://meblohand.api.imilo.pl/";
const meblohandTest = "https://meblohand.test.api.imilo.pl/";

// pakko
const pakko = "https://pakko.api.imilo.pl/";
const pakkoTest = "https://pakko.test.api.imilo.pl/";

// pwp
const pwp = "https://pwp.api.imilo.pl/";
const pwpTest = "https://pwp.test.api.imilo.pl/";

// optisofa
const optisofa = "https://optisofa.api.imilo.pl/";
const optisofaTest = "https://optisofa.test.api.imilo.pl/";

// ys24
const yh24 = "https://yh24.api.imilo.pl/";
const yh24Test = "https://yh24.test.api.imilo.pl/";

// ec
const ec = "https://ec.api.imilo.pl/";
const ecTest = "https://ec.test.api.imilo.pl/";

const s1 = "https://s1.api.imilo.pl/";
const s2 = "https://s2.api.imilo.pl/";
const s3 = "https://s3.api.imilo.pl/";
const s4 = "https://s4.api.imilo.pl/";
const s5 = "https://s5.api.imilo.pl/";


export const DOMAIN = (function() {
  const api = process.env.REACT_APP_API;

  if (api === "s1") return s1;
  if (api === "s2") return s2;
  if (api === "s3") return s3;
  if (api === "s4") return s4;
  if (api === "s5") return s5;

  if (api === "local") return local;
  if (api === "development") return development;
  if (api === "demo") return demo;

  if (api === "amsline") return amsline;
  if (api === "amslineTest") return amslineTest;

  if (api === "bromarkt") return bromarkt;
  if (api === "bromarktTest") return bromarktTest;

  if (api === "drewmix") return drewmix;
  if (api === "drewmixTest") return drewmixTest;

  if (api === "gib") return gib;
  if (api === "gibTest") return gibTest;

  if (api === "gg") return gg;
  if (api === "ggTest") return ggTest;

  if (api === "grekpol") return grekpol;
  if (api === "grekpolTest") return grekpolTest;

  if (api === "idm") return idm;
  if (api === "idmTest") return idmTest;

  if (api === "kef") return kef;
  if (api === "kefTest") return kefTest;

  if (api === "kenix") return kenix;
  if (api === "kenixTest") return kenixTest;

  if (api === "lukmebel") return lukmebel;
  if (api === "lukmebelTest") return lukmebelTest;

  if (api === "mteam") return mteam;
  if (api === "mteamTest") return mteamTest;

  if (api === "moderno") return moderno;
  if (api === "modernoTest") return modernoTest;

  if (api === "martimeble") return martimeble;
  if (api === "martimebleTest") return martimebleTest;

  if (api === "mars") return mars;
  if (api === "marsTest") return marsTest;

  if (api === "mebline") return mebline;
  if (api === "meblineTest") return meblineTest;

  if (api === "meblohand") return meblohand;
  if (api === "meblohandTest") return meblohandTest;

  if (api === "pakko") return pakko;
  if (api === "pakkoTest") return pakkoTest;

  if (api === "pwp") return pwp;
  if (api === "pwpTest") return pwpTest;

  if (api === "optisofa") return optisofa;
  if (api === "optisofaTest") return optisofaTest;

  if (api === "yh24") return yh24;
  if (api === "yh24Test") return yh24Test;

  if (api === "ec") return ec;
  if (api === "ecTest") return ecTest;

  return development;
})();
