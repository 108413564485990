import { FinanceReportBalance } from "api/finances/models";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import styles from "./styles.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styled from "@emotion/styled";
import { LineChart, LineChartProps } from "components/utils/charts/lineChart/LineChart";

interface Props {
  data: FinanceReportBalance;
}

export const BalanceLineChart = ({ data }: Props) => {
  const formattedData: LineChartProps["data"] = (() => {
    return [
      {
        data: data.details.flatMap(detail => {
          return {
            value: Number(detail.expense.total.net?.amount),
            label: detail.timestamp,
          };
        }),
        color: colorPalette.magenta200,
      },
      {
        data: data.details.flatMap(detail => {
          return {
            value: Number(detail.income.total.net?.amount),
            label: detail.timestamp,
          };
        }),
        color: colorPalette.deepPurple400,
      },
    ];
  })();

  return (
    <div className={styles.card}>
      <Typography fontSize="16" fontWeight="600" className="mb-2 align-self-start">
        Przychody vs koszty
      </Typography>
      <div className="d-flex align-items-center gap-3 justify-content-center mb-2">
        <div className="d-flex align-items-center gap-1">
          <CurrencyColor backgroundColor={colorPalette.deepPurple400} />
          <Typography fontSize="10" fontWeight="500">
            Przychody
          </Typography>
        </div>
        <div className="d-flex align-items-center gap-1">
          <CurrencyColor backgroundColor={colorPalette.magenta200} />
          <Typography fontSize="10" fontWeight="500">
            Koszty
          </Typography>
        </div>
      </div>
      <div style={{ height: 290, width: 442 }}>
        <LineChart yAxisPadding={0} data={formattedData} />
      </div>
    </div>
  );
};

const CurrencyColor = styled.div<{
  backgroundColor: string;
}>`
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: ${props => props.backgroundColor};
`;
