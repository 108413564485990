import { Switch, RouteComponentProps } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { FinancesBalance } from "pages/crm/financesBalance/FinancesBalance";

export const FinancesBalanceRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}/:businessEntityId`}
        component={FinancesBalance}
        title="Milo - bilans przychodów i kosztów"
      />

      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
