import { UUID } from "api/types";
import { useQuery } from "hooks";
import { useParams } from "react-router";
import { dateFns } from "utilities";

export const useFinancesFilters = () => {
  const { query } = useQuery();
  const { businessEntityId } = useParams<{ businessEntityId: UUID }>();

  return {
    businessEntityId,
    months: query.months || "1,2,3",
    years: query.years || dateFns.format(new Date(), "yyyy"),
  };
};
